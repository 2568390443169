import Plyr from 'plyr'
import 'plyr/dist/plyr.css'

export class MainPlayer
  constructor: ->
    @totalSteps = 0
    @currentStep = null
    @currentType = null
    @locFolder = null
      

  init: ->
    @player = new Plyr('#audio-player')
    @player.on('ended', =>
      if @currentStep < @totalSteps
        @currentType = 'intermezzo'
        @highlightCurrent()
        @_play()
    )

  playLocation: (locItem) ->
    @currentStep = locItem.data('stepNum')
    @currentType = locItem.data('type')
    @locFolder =   locItem.data('locationId')
    scanCode =     locItem.data('code')
    @locFolder += '-' + scanCode if scanCode.length > 0
    
    @player.stop()
    @_play()


  highlightCurrent: ->
    locationsList = $('#locations-list')
    locationsList.children('ons-list-item').removeClass('selected')
    locationsList.children('[data-type="intermezzo"]').children().hide()

    [chapter, intermezzo] = locationsList.children("[data-step-num=\"#{@currentStep}\"]")
    $(intermezzo).children().show()
    acld = $("#acld-intermezzo")
    switch @currentType
      when 'chapter'
        $(chapter).addClass('selected')
        acld.hide()
      when 'intermezzo'
        $(intermezzo).addClass('selected')
        # set hint link and show button
        { locationId, code, type } = $(intermezzo).data()
        locationId += '-' + code if code.length > 0
        acld.data('locationId', locationId)
        acld.data('locationType', type)
        acld.show()
    return


  destroy: ->
    @player.stop()
    @player.destroy


  _play: (title) ->
    @player.source =
      type: 'audio',
      sources: [
        { src: "/location_details/#{@locFolder}/#{@currentType}.mp3", type: "audio/mp3" },
        { src: "/location_details/#{@locFolder}/#{@currentType}.ogg", type: "audio/ogg" }
      ]
    caption = $('#audio-caption-track-info')
    switch @currentType
      when 'chapter'
        @player.loop = false
        caption.text("Kapitola #{@currentStep}")
      when 'intermezzo'
        @player.loop = true
        caption.text("Intermezzo")        
    @player.play()

